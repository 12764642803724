import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import StockPost from './StockPost';
import Pagination from '../Pagination/Pagination';

import './Stocks.scss';

const Stocks = ({ posts, title }) => {

  const rootRef = useRef();

  useEffect(() => {
    page !== 1 && window.scrollTo({ top: rootRef.current.offsetTop - 20 });
  });
  const [page, changePage] = useState(1);

  const postsInPage = [];
  const pages = [];

  posts.forEach((post, index) => {
    if (index >= (page - 1) * 6 && index < page * 6) {
      postsInPage.push(post);
    }
  });

  const pagesCount = posts.length / 6;

  for (let i = 1; i < pagesCount + 1; i++) {
    pages.push(i);
  }

  return (
    <div className='stocks' ref={rootRef}>
      <h1 className='stocks-title'>{title}</h1>
      <div className='stocks-container'>
        {postsInPage.map(({ headLine, path, image, promoDescription }) => (
          <StockPost
            key={path} headLine={headLine}
            image={image} path={path}
            promoDescription={promoDescription}
          />
        ))}
      </div>
      <Pagination
        pages={pages} changePage={changePage}
        page={page}
      />
    </div>
  );
};

export default Stocks;

Stocks.propTypes = {
  posts: PropTypes.array,
  title: PropTypes.string
};
