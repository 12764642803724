import React from 'react';
import PropTypes from 'prop-types';

import './PostDescription.scss';

const PostDescription = ({ headLine, promoDescription }) => {
  return (
    <div className='post-description-container'>
      <h3 className='post-description-title'>{headLine}</h3>
      <p className='post-description-subtitle'>{promoDescription}</p>
    </div>
  );
};

export default PostDescription;

PostDescription.propTypes = {
  headLine: PropTypes.string,
  promoDescription: PropTypes.string
};
