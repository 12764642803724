import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import PostImage from './PostImage';
import PostDescription from './PostDescription';

import './StockPost.scss';

const StockPost = ({ headLine, path, image, promoDescription }) => {
  return (
    <Link to={path} className='stock'>
      <PostImage image={image} headLine={headLine} />
      <PostDescription headLine={headLine} promoDescription={promoDescription}/>
    </Link>
  );
};

export default StockPost;

StockPost.propTypes = {
  headLine: PropTypes.string,
  path: PropTypes.string,
  image: PropTypes.string,
  promoDescription: PropTypes.string
};
