import React from 'react';
import PropTypes from 'prop-types';

import './PostImage.scss';

const PostImage = ({ headLine, image }) => {
  return (
    <div className='post-image-container'>
      <img
        className='post-image' src={image}
        alt={headLine}
      />
    </div>
  );
};

export default PostImage;

PostImage.propTypes = {
  headLine: PropTypes.string,
  image: PropTypes.string
};
